<template>
  <div id="strategylist">
    <div class="top">
      <img class="logo" src="https://static.ainvestcn.com/h5/topLogo%402x.fdcb6507.png" alt>
      <img
        v-if="unlogin"
        src="https://static.ainvestcn.com/h5/loginbtn2.png"
        alt
        class="button"
        @click="gotologin()"
      >
    </div>
    <div class="middle">
      <van-dropdown-menu style="position:fixed;width:100%;z-index:1; box-shadow: 0px 2px 2px #888888">
        <!-- <van-dropdown-item v-model="filter1value" :options="filter1option" @change="filter1change()" /> -->
        <van-dropdown-item v-model="filter2value" :options="filter2option" @change="filter2change()" />
        <!-- <van-dropdown-item v-model="value2" :options="option2" /> -->
      </van-dropdown-menu>
      <div style="margin-top: 10.1vh;">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
        >
          <div v-for="(item,index) in list" :key="index" class="card-item" @click="showDetail(item.strategy.id)">
            <!-- <div class="recommend"><img src="@/assets/img/recommend.png" alt="" class="recommend-img"></div> -->
            <div class="topline">
              <div class="title">{{ item.strategy.name }}</div>
              <div class="accumulate">累计收益率</div>
              <div class="valuea">{{ item.totalReturn }}%</div>
            </div>
            <div class="middleline">
              <div class="marginright15">
                <span class="key">年化收益率</span>
                <span class="valuer">{{ item.annualizedRateReturn }}</span>
              </div>
              <div class="marginright15">
                <span class="key">夏普比率</span>
                <span class="valuer">{{ item.sharpRatio }}</span>
              </div>
              <div>
                <span class="key">日收益率</span>
                <span class="valuer">{{ item.dailyRateReturns }}</span>
              </div>
            </div>
            <div class="bottomline">
              <!-- <div class="formoney">
                适用资金: {{ item.applicable_funds?item.applicable_funds:'20 W' }}
              </div> -->
              <div class="see">查看详情>></div>
              <!-- <img class="fund" src="@/assets/img/fund.png" alt=""> -->
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <!--

		-->
    <div class="bottom">
      <van-tabbar route fixed>
<!--        <van-tabbar-item to="/home">-->
<!--          <img-->
<!--            slot="icon"-->
<!--            slot-scope="props"-->
<!--            :src="props.active ? icons.home.active : icons.home.normal"-->
<!--          >-->
<!--          <span>首页</span>-->
<!--        </van-tabbar-item>-->
        <van-tabbar-item to="/strategylist">
          <img
            slot="icon"
            slot-scope="props"
            :src="props.active ? icons.mall.active : icons.mall.normal"
          >
          <span>策略商城</span>
        </van-tabbar-item>
<!--        <van-tabbar-item to="/user">-->
<!--          <img-->
<!--            slot="icon"-->
<!--            slot-scope="props"-->
<!--            :src="props.active ? icons.user.active : icons.user.normal"-->
<!--          >-->
<!--          <span>个人中心</span>-->
<!--        </van-tabbar-item>-->
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import {
	List,
	Tabbar,
	TabbarItem,
	Icon,
	Tab,
	DropdownMenu,
	DropdownItem
} from 'vant'
import { islogin, logout } from '@/api/theApi'
import { strategyList } from '@/api/strategyApi'
export default {
	components: {
		[List.name]: List,
		[Tabbar.name]: Tabbar,
		[TabbarItem.name]: TabbarItem,
		[Icon.name]: Icon,
		[Tab.name]: Tab,
		[DropdownMenu.name]: DropdownMenu,
		[DropdownItem.name]: DropdownItem
	},
	data() {
		return {
			list: [],
			loading: true,
			error: false,
			finished: false,
			filter1value: 0,
			filter1index: 0,
			filter2value: 0,
			listall: [],

			filter1option: [
				{
					text: '适用资金(默认)',
					value: 0,
					index: 0,
					min: 0,
					max: 1000
				},
				{
					text: '2万-10万',
					value: 1,
					index: 1,
					min: 2,
					max: 10
				},
				{
					text: '10万-30万',
					value: 2,
					index: 2,
					min: 10,
					max: 30
				},
				{
					text: '30万-60万',
					value: 3,
					index: 3,
					min: 30,
					max: 60
				},
				{
					text: '60万-100万',
					value: 4,
					index: 4,
					min: 60,
					max: 100
				}
			],
			filter2option: [
				{
					text: '累计收益率',
					value: 0
				},
				{
					text: '年化回报率',
					value: 1
				},
				{
					text: '夏普比率',
					value: 2
				},
				{
					text: '日收益率',
					value: 3
				}
			],
			icons: {
				home: {
					name: '首页',
					active: 'https://static.ainvestcn.com/h5/icon/home-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/home-icon.png'
				},
				mall: {
					name: '策略商城',
					active: 'https://static.ainvestcn.com/h5/icon/shop-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/shop-icon.png'
				},
				user: {
					name: '用户中心',
					active: 'https://static.ainvestcn.com/h5/icon/user-icon-a.png',
					normal: 'https://static.ainvestcn.com/h5/icon/user-icon.png'
				}
			},
			unlogin: false
		}
	},
	// computed: {
	// 	recommend() {

	// 	}
	// },
	created() {
		this.init()
	},
	methods: {
		async init() {
			const res = await strategyList()
			this.list = res.data

			this.list = this.list.filter(item => {
				return item.dailyStateId !== null
			})
			this.listall = this.list
			this.loading = false
			console.log(1)
			this.finished = true
			const isloginRes = await islogin()
			const username = localStorage.getItem('username')

			if (isloginRes.login_status && username) {
				// Toast.loading('已经登陆')
				// const b64u = Base64.encode(username)
				this.unlogin = false
			} else {
				// localStorage.clear()
				this.unlogin = true
			}

			if (!isloginRes.login_status && username) {
				this.unlogin = true

				localStorage.clear()
			}

			if (isloginRes.login_status && !username) {
				this.unlogin = true

				// Toast.fail('登录过期\n请重新登陆')
				logout().then(() => {})
			}
		},
		onLoad() {
			// 异步更新数据
			setTimeout(() => {
				// 获取数据push数据
				// 加载状态结束
				this.loading = false
				// 数据全部加载完成
				if (this.list.length >= 1) {
					this.finished = true
				}
			}, 500)
		},
		showDetail(startegy_id) {
			// console.log(startegy_id)
			const targeturl = '/strategy/detail/' + startegy_id
			this.$router.push({
				path: targeturl
			})
		},
		gotologin() {
			this.$router.push({
				path: '/login'
			})
		},
		filter1change() {
			// console.log(this.filter1value)

			// console.log(this.filter1option[this.filter1value].min)
			// console.log(this.filter1option[this.filter1value].max)
			// console.log(this.filter1option[this.filter1value])

			const min = this.filter1option[this.filter1value].min
			const max = this.filter1option[this.filter1value].max

			// 数据更替
			this.list = this.listall.filter(item => {
				const itemvalue = parseInt(item.applicable_funds.split('万')[0])
				return itemvalue >= min && itemvalue <= max
			})
		},
		filter2change() {
			if (this.filter2value === 0) {
				// console.log(this.filter2value)
				this.list = this.listall.sort((a, b) => {
					// console.log(a.total_return.replace('%', '') - b.total_return.replace('%', ''))
					return (
						parseFloat(b.total_return.replace('%', '')) -
            parseFloat(a.total_return.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 1) {

				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.annualized_return.replace('%', '')) -
            parseFloat(a.annualized_return.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 2) {
				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.sharp_value.replace('%', '')) -
            parseFloat(a.sharp_value.replace('%', ''))
					)
				})
			}
			if (this.filter2value === 3) {
				this.list = this.listall.sort((a, b) => {
					return (
						parseFloat(b.daily_return.replace('%', '')) -
            parseFloat(a.daily_return.replace('%', ''))
					)
				})
			}
			// 数据更替
		}
	}
}
</script>
<style lang="less" scoped>
#homev2 {
	position: relative;
	height: 100vh;
	width: 100vw;
	// display: flex;
	// flex-direction: column;
}
#strategylist{
	background: #ffffff;
	width: 100%;
	// height: 100%;
}
.top {
	// bottom: 4rem;
	top: 0;
	// height:3.5rem;
	position: fixed;
	height: 7.5vh;
	z-index: 1;
	width: 100vw;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.18);
	.logo {
		width: 42vw;
		// height: 5vh;
		margin-left: 3.4vw;
		margin-top: 1.2vh;
	}
	.button {
		width: 18vw;
		float: right;
		height: 9vw;
		margin-right: 3.5vw;
		margin-top: 1.7vh;
	}
}
.middle {
	bottom: 7.5vh;
	top: 7.5vh;
	// height: 15rem;
	position: absolute;
	width: 100vw;
	.login {
		width: 100vw;
		margin-top: 55.5vh;
	}
	.reg {
		margin-top: -1.7vh;
		width: 90.3vw;
		margin-left: 4.8vw;
	}
	.list-item {
		display: flex;
		div {
			position: absolute;
		}
	}
	.card-item {
		// width: 100vw;
		// height: 19vh;
		width: 92vw;
    // height: 19vh;
    height: 15vh;
		background-color: #ffffff;
		box-shadow: 0vw 0vw 2vw 0vw rgba(204, 204, 204, 0.36);
		border-radius: 1vw;
		background-color: rgba(255, 255, 255, 1);
		box-shadow:0px 0px 16px 0px rgba(204,204,204,0.36);
		border-radius:6px;
		margin: 0 auto;
		margin-top: 2vh;
		// background-image: url("https://static.ainvestcn.com/h5/listcard.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
    .recommend-img{
    width: 7vh;
    height: 7vh;
    float: right;
    }
		.title {
			position: absolute;
			top: 3vh;
			left: 4vw;
			font-size: 5vw;
			height: 4vw;
			font-weight: bold;
		}
		.accumulate{
			position: absolute;
			top: 4vh;
			right: 30vw;
			font-size: 3vw;
			font-weight: 700;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #000000;
		}
		.valuea{
			position: absolute;
			top:2.3vh;
			right: 4.2vw;

			font-size:5.5vw;
			font-family: sans-serif;
			font-weight: bold;
			line-height: 2rem;
			color: rgba(255, 64, 64, 1)
		}
		.middleline{
			position: absolute;
			top:7vh;
			left: 3vw;
			padding: 1vh;
			background-color: #f5f5f5;
			border-radius: 1vw;
			display: inline-flex;
			.marginright15{
				margin-right: 2vw;
			}
			.key {
				color:rgba(102, 102, 102, 1);
				font-size:3vw;
			}
			// hello teset
			.valuer{
				margin-left:8px;
				color:rgba(255, 52, 52, 1);
				font-size:3vw;

			}
			.valueg{
				margin-left:8px;
				font-size:3vw;
				color:rgba(61, 171, 31, 1)
			}
		}
		.bottomline{
			position: absolute;
			top: 12.5vh;
			// left:10vw;
			// height: 1.8vh;
			width:90vw;
			display: inline-flex;
			.formoney{
				// color: rgba(255, 64, 64, 1);
				// font-weight: bold;
				// line-height: 4.5vw;
				// font-size: 3vw;
				margin-top: 1vh;
				margin-left:7vw;
				padding: 1vh 2vw;
				background-color: #fff0f0;
				border-radius: 1vh;
				font-size: 3vw;
				color: #ff4040;
			}
			.see{
				height: 3vw;
				font-size: 3vw;
				letter-spacing: 0vw;
				color: #666666;
				margin: auto 0 auto 5vw;
			}
			.fund{
        position: absolute;
        top:1vh;
        left: 2vw;
        display: block;
        width: 7vw;
        height: 7vw;
			}
		}
	}
}
.bottom {
	bottom: 0;
	// height:3.5rem;
	height: 7.5vh;
	position: absolute;
	width: 100vw;
	// background-color: rgba(255, 255, 255, 0.048);
	.active {
		color: rgba(0, 0, 0, 1);
	}
	.normal {
		color: rgba(102, 102, 102, 1);
	}
}
</style>
