import request from '@/utils/http'

/**
 * 获取策略列表及最新表现
 */
export function strategyList() {
	const data = { nativeCode: '24f4M14045PAinvestb82I40EQ4Ad' }
	return request({
		url: '/v4/strategy/latest',
		method: 'post',
		data
	})
}

export function strategyDetail(id) {
	const data = {
		nativeCode: '24f4M14045PAinvestb82I40EQ4Ad',
		strategyid: id
	}
	return request({
		url: '/v4/strategy/detail',
		method: 'post',
		data
	})
}
